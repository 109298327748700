<div class="header" layout="column" (click)="openMenu()" flex>
  <!-- Left-Hand MSIX Logo -->
  <div class="toolbar" *ngIf="htmlExportService.toolbarDisplay" flex>
    <div class="md-toolbar-tools layout-row">
      <div class="left flex-inline">
        <a [routerLink]="loggedIn ? '/home' : '/'">
          <img src="../../../msix/assets/images/logos/MSIX.png" alt="MSIX Logo" class="msixLogo">
        </a>
      </div>
      
      <span flex></span>
      <div class="inline align-header-icon" hide-gtmd aria-label="Open side navigation" (click)="toggleSideNav()">
        <i class="fa fa-bars header-icon"></i>
      </div>

      <!-- Right-Hand Dropdown Menu -->
      <div (click)="openMenu()"  layout="row" class="tooltip right-align layout-column" 
        *ngIf="loggedIn && privacyComplete && !needsJobTitle">
        <button tabindex="0" (keypress)="openMenu()" mat-button [matMenuTriggerFor]="topRightMenu" class="layout-row color-grey2 welcome-btn">
          Welcome 
          <span class="bold-text">&nbsp;{{user}}</span>
          <i class="menu-icon fa fa-caret-down color-green" aria-hidden="true"></i>
        </button>
        <!--style=" display: flex; z-index: 101; position: sticky; margin-top: 15px;"-->
        <mat-menu tabindex="0" class="right-align mat-menu-panel" #topRightMenu="matMenu" style=" display: flex; z-index: 101; position: sticky; margin-top: 15px;">
          <div style="min-width: 192px;" tabindex="0">
            <button tabindex="0" mat-menu-item class="mat-menu-item" style="background-color: #FFF;"
            (click)="goToAccount(); toggleSideNav()" *ngIf="!isLoggedInWithOTP">
              <div layout="row" style="display: flex; justify-content: center; align-items: center;">
                <i flex=20 class="menu-icon fa fa-cog color-green top-bottom-margin-4 " aria-hidden="true"></i>
                <p flex=80 class="small no-padding-t-b-r no-top-bottom-margin">My Account</p>
              </div>
            </button><br>
            <button tabindex="0" mat-menu-item class="mat-menu-item" style="margin-top: -22px; background-color: #FFF;" 
            (click)="signOut(); toggleSideNav()">
              <div layout="row" style="display: flex; justify-content: center; align-items: center;">
                <i flex=20 class="menu-icon fa fa-sign-out color-green top-bottom-margin-4 " aria-hidden="true"></i>
                <p flex=80 class="small no-padding-t-b-r no-top-bottom-margin">Sign Out</p>
              </div>
            </button>
          </div>
        </mat-menu>
        <!-- <div class="layout-column" *ngIf="menuOpen">

        </div> -->
      </div> <!--End Menu-->
    </div>
  </div> <!--End toolbar-->

  <div layout="row" class="headerRow" flex *ngIf="!((router === '/' || router === '/login' 
    || router.includes('/pwReset') || router.includes('/adminSearchResults')
    || router === '/setChallengeQuestions' || router === '/answerChallengeQuestions'
    || router === '/forgot-password' || router === '/checkEmailMsg'
    || router === '/rulesOfBehavior' || router === '/passwordUpdate' || router === '/mfaSetup'
    || router === '/mfaDownload' || router === '/mfaRegister' || router === '/mfaVerify'
    || router === '/mfaSuccess') && responsiveUI.isDesktop)">
    <!-- Backdrop -->
    <div (click)="toggleSideNav()" *ngIf="showSideNav" class="md-backdrop"></div>
    <!-- Begin Side Nav Bar -->
    <div class="md-sidenav md-whiteframe-4dp" id="sidenav-right">

      <div class="md-list">

        <!-- User is logged out -->
        <!-- <div class="block" [ngClass]="{greenIcon: router==='/requestAccount'}" 
            *ngIf="!loggedIn">
          <div class="clickable md-list-item section3" routerLink="/requestAccount">
            <div class="sidemenu-spacing uppercase">
              Request an account
            </div>
          </div>
        </div> -->

        <!--user is logged in - START -->
        <div class="section1" *ngIf="loggedIn && privacyComplete && !needsJobTitle">
          <div class="block" [ngClass]="{greenIcon: router === '/home'}" aria-label="Dashboard">
            <div class="top-block clickable" (keypress)="goToHomePage(); toggleSideNav()" tabindex="0" (click)="goToHomePage(); toggleSideNav()">
              <div class="sidemenu-spacing">
                <i class="fa fa-th-large" aria-hidden="true"></i>
              </div>
              <div class="sidemenu-spacing uppercase">
                Home
              </div>
            </div>
          </div>
          <div class="block" [ngClass]="{greenIcon: (router?.indexOf('searchResults')!== -1)}" *ngIf="authRoleClasses.studentSearch">
            <div class="clickable" (keypress)="goToSearchPage(); toggleSideNav()" tabindex="0" (click)="goToSearchPage(); toggleSideNav()">
              <div class="sidemenu-spacing">
                <i class="fa fa-search" aria-hidden="true"></i>
              </div>
              <div class="sidemenu-spacing uppercase">
                Search
              </div>
            </div>
          </div>

          <div class="block" [ngClass]="{greenIcon: (router==='app.worklist' || router==='/worklist')}"  *ngIf="authRoleClasses.worklist || authRoleClasses.sendInfo">
            <div class="clickable" (keypress)="goToWorkList(); toggleSideNav()" tabindex="0" (click)="goToWorkList(); toggleSideNav()">
              <div class="sidemenu-spacing">
                <i class="fa fa-briefcase" aria-hidden="true"></i>
              </div>
              <div class="sidemenu-spacing uppercase">
                Worklist
              </div>
              <span style="flex: 1; box-sizing: border-box;"></span>
              <div class="worklistItems flex-container" *ngIf="cookieService.getObject('worklistCount') !== 0">
                {{cookieService.getObject('worklistCount')}}
              </div>
            </div>
          </div>

          <div class="block" [ngClass]="{greenIcon: (router==='app.savedList' || router==='/savedList')}" *ngIf="authRoleClasses.studentSearch">
            <div class="clickable" (keypress)="goToSavedList(); toggleSideNav()" tabindex="0" (click)="goToSavedList(); toggleSideNav()">
              <div class="sidemenu-spacing">
                <i class="fa fa-archive" aria-hidden="true"></i>
              </div>
              <div class="sidemenu-spacing uppercase">
                My Lists
              </div>
            </div>
          </div>
          <div class="block" *ngIf="authRoleClasses.reports">
            <div class="clickable" (keypress)="goToReportsNew(); toggleSideNav()" tabindex="0" (click)="goToReportsNew(); toggleSideNav()">
              <div class="sidemenu-spacing">
                <i class="fa fa-file-text" aria-hidden="true"></i>
              </div>
              <div class="sidemenu-spacing uppercase">
                Reports
              </div>
            </div>
          </div>

          <div class="block" [ngClass]="{greenIcon: router === '/snapshot'}" *ngIf="authRoleClasses.reports">
            <div class="clickable" (keypress)="goToSnapshot(); toggleSideNav()" tabindex="0" (click)="goToSnapshot(); toggleSideNav()">
              <div class="sidemenu-spacing">
                <i class="fa fa-camera" aria-hidden="true"></i>
              </div>
              <div class="sidemenu-spacing uppercase">
                Snapshot
              </div>
            </div>
          </div>

          <div class="block" [ngClass]="{greenIcon: (router==='app.dataAdmin' || router==='/dataAdmin')}" *ngIf="(authRoleClasses.enableRegion || authRoleClasses.editRegion) && responsiveUI.isDesktop">
            <div class="clickable" (keypress)="goToStateRegion(); toggleSideNav()" tabindex="0" (click)="goToStateRegion(); toggleSideNav()">
              <div class="sidemenu-spacing">
                <i class="fa fa-globe" aria-hidden="true"></i>
              </div>
              <div class="sidemenu-spacing uppercase">
                State/Regions
              </div>
            </div>
          </div>

          <div class="block" *ngIf="authRoleClasses.stateAdmin || authRoleClasses.regionalAdmin || authRoleClasses.districtAdmin || authRoleClasses.passwords" [ngClass]="{greenIcon: (router==='app.userAdmin' || router==='/userAdmin')}">
            <div class="clickable" (keypress)="goToAdminPage(); toggleSideNav()" tabindex="0" (click)="goToAdminPage(); toggleSideNav()">
              <div class="sidemenu-spacing">
                <i class="fa fa-user" aria-hidden="true"></i>
              </div>
              <div class="sidemenu-spacing uppercase">
                Administration
              </div>
            </div>
          </div>

        </div>

        <div class="nav-bar1" *ngIf="loggedIn && privacyComplete && !needsJobTitle"></div>

        <div class="block section-start" *ngIf="loggedIn && privacyComplete && !needsJobTitle">
          <div>
            <div class="clickable" (keypress)="goToHelp(); toggleSideNav()" tabindex="0" (click)="goToHelp(); toggleSideNav()">
              <div class="sidemenu-spacing">
                <i class="fa fa-book" aria-hidden="true"></i>
              </div>
              <div class="sidemenu-spacing uppercase">
                Help
              </div>
            </div>
          </div>


          <div class="block" [ngClass]="{greenIcon: (router==='app.myaccount' || router==='/myaccount')}">
            <div class="clickable" (keypress)="goToAccount(); toggleSideNav()" tabindex="0" (click)="goToAccount(); toggleSideNav()">
              <div class="sidemenu-spacing">
                <i class="fa fa-cog" aria-hidden="true"></i>
              </div>
              <div class="sidemenu-spacing uppercase">
                My account
              </div>
            </div>
          </div>

          <div class="block">
            <div class="clickable" (keypress)="signOut(); toggleSideNav()" tabindex="0" (click)="signOut(); toggleSideNav()">
              <div class="sidemenu-spacing">
                <i class="fa fa-sign-out" aria-hidden="true"></i>
              </div>
              <div class="sidemenu-spacing uppercase">
                Sign out
              </div>
            </div>
          </div>
        </div>

        <div class="nav-bar2" *ngIf="loggedIn && privacyComplete && !needsJobTitle"></div>

        <div [ngClass]="{section3: loggedIn && privacyComplete}" *ngIf="loggedIn && !needsJobTitle">

          <div class="block" *ngIf="loggedIn">
            <div class="clickable" tabindex="0" (keypress)="goToTraining(); toggleSideNav()" (click)="goToTraining(); toggleSideNav()">
              <div class="sidemenu-spacing uppercase">
                Training
              </div>
            </div>
          </div>

          <div class="block" [ngClass]="{greenIcon: (router==='/resources')}">
            <div class="clickable" tabindex="0" (keypress)="goToResources(); toggleSideNav()" (click)="goToResources(); toggleSideNav()">
              <div class="sidemenu-spacing uppercase">
                Resources
              </div>
            </div>
          </div>

          <div class="block" [ngClass.greenIcon]="(router==='/help')" [ngClass.end-page]="loggedIn">
            <div class="clickable" tabindex="0" (keypress)="goToContact(); toggleSideNav()" (click)="goToContact(); toggleSideNav()">
              <div class="sidemenu-spacing uppercase">
                Contact
              </div>
            </div>
          </div>
        </div>
        <!--user is logged in - END -->

        <!--Limited sidebar navigation when user is redirected to select job titles-->
        <div class="section2" *ngIf="!loggedIn || (privacyComplete && needsJobTitle)">
          <div class="block" [ngClass]="{greenIcon: router==='/requestAccount'}">
            <div class="clickable" tabindex="0" (keypress)="goToRequestAccount(); toggleSideNav()" (click)="goToRequestAccount(); toggleSideNav()">
              <div class="sidemenu-spacing uppercase">
                Request an account
              </div>
            </div>
          </div>

          <div class="block" [ngClass]="{greenIcon: router==='/resources'}">
            <div class="clickable" tabindex="0" (keypress)="goToResources(); toggleSideNav()" (click)="goToResources(); toggleSideNav()">
              <div class="sidemenu-spacing uppercase">
                Resources
              </div>
            </div>
          </div>

          <div class="block" [ngClass]="{greenIcon: router==='/help'}">
            <div class="clickable" tabindex="0" (keypress)="goToContact(); toggleSideNav()" (click)="goToContact(); toggleSideNav()">
              <div class="sidemenu-spacing uppercase">
                Contact
              </div>
            </div>
          </div>

          <div class="block">
            <div class="clickable" tabindex="0" (keypress)="goToLoginPage(); toggleSideNav()" (click)="goToLoginPage(); toggleSideNav()">
              <div class="sidemenu-spacing uppercase">
                Login
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="center">
        <img src="../../../msix/assets/images/backgrounds/Mark-menu-sidebar.svg" class="sidemenu-background">
      </div>
      
    </div>
  </div>
</div>
