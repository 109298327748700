<div *ngIf="dataRequestItems.length == 0" class="layout-margin-worklist-module layout-padding-worklist-module">
  <h2>No Data Request items found.</h2>
  <p>Data requests that have been sent to you from other areas as well as data requests that you have initiated will be
    shown
    here.
  </p>
  <p>To initiate a data request, start by
    <a (click)="goToSearch()">searching for records</a>
  </p>
</div>


<!--START Data Request Desktop-->

<div *ngIf="responsiveUI.isDesktop && dataRequestItems.length > 0"
  class="worklist-page layout-margin-worklist-module layout-padding-worklist-module">

  <div class="layout-padding-worklist-submodule background-white">
    <div class="desktopEnrollments removeBorders">
      <div class="tableRow headerRow layout-flex-row">
        <h3>Data Request</h3>
      </div>
    </div>
    <div class="sub-header-wrapper">
      <p class="flex-80 worklist-description-text">
        Expand the row to view details, correspond directly with the requestor, or resolve data requests assigned to
        you. Action must be taken within four calendar days from the request creation date.
      </p>
      <div >
        <p>Showing:</p>
        <mat-form-field appearance="outline">
          <mat-select name="DRFilterOptionsSelect" [(ngModel)]="selectedDRFilter">
            <mat-option *ngFor="let option of DRFilterOptions" [selected]="'All Open Requests'" (click)="filterDROptions(selectedDRFilter)" 
              [value]="option">
              {{option}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>

    <div class="worklist-header-titles layout-flex-row">
      <div class="flex-95">
        <div class="flex-container flex-90">
          <div class="block2 flex-20">
            <a (click)="sortColumns('column1')">
              Student
              <i class="fa fa-caret-down fa-lg" aria-hidden="true"
                *ngIf="'column1'===currentSortColumn && !sortAscending"></i>
              <i class="fa fa-caret-up fa-lg" aria-hidden="true"
                *ngIf="'column1'===currentSortColumn && sortAscending"></i>
            </a>
          </div>
          <div class="block2 flex">
            <a (click)="sortColumns('column2')">
              State
              <i class="fa fa-caret-down fa-lg" aria-hidden="true"
                *ngIf="'column2'===currentSortColumn && !sortAscending"></i>
              <i class="fa fa-caret-up fa-lg" aria-hidden="true"
                *ngIf="'column2'===currentSortColumn && sortAscending"></i>
            </a>
          </div>
          <div class="block2 flex-15">
            <a (click)="sortColumns('column3')">
              MSIX ID
              <i class="fa fa-caret-down fa-lg" aria-hidden="true"
                *ngIf="'column3'===currentSortColumn && !sortAscending"></i>
              <i class="fa fa-caret-up fa-lg" aria-hidden="true"
                *ngIf="'column3'===currentSortColumn && sortAscending"></i>
            </a>
          </div>
          <div class="block2 flex-15">
            <a (click)="sortColumns('column4')">
              State ID
              <i class="fa fa-caret-down fa-lg" aria-hidden="true"
                *ngIf="'column4'===currentSortColumn && !sortAscending"></i>
              <i class="fa fa-caret-up fa-lg" aria-hidden="true"
                *ngIf="'column4'===currentSortColumn && sortAscending"></i>
            </a>
          </div>
          <div class="block2 flex-10">
            <a (click)="sortColumns('column5')">
              Worklist
              <i class="fa fa-caret-down fa-lg" aria-hidden="true"
                *ngIf="'column5'===currentSortColumn && !sortAscending"></i>
              <i class="fa fa-caret-up fa-lg" aria-hidden="true"
                *ngIf="'column5'===currentSortColumn && sortAscending"></i>
            </a>
          </div>
          <div class="block2 flex-10">
            <a (click)="sortColumns('column6')">
              Initiated By
              <i class="fa fa-caret-down fa-lg" aria-hidden="true"
                *ngIf="'column6'===currentSortColumn && !sortAscending"></i>
              <i class="fa fa-caret-up fa-lg" aria-hidden="true"
                *ngIf="'column6'===currentSortColumn && sortAscending"></i>
            </a>
          </div>
          <div class="block2 flex-15">
            <a (click)="sortColumns('column7')">
              Created
              <i class="fa fa-caret-down fa-lg" aria-hidden="true"
                *ngIf="'column7'===currentSortColumn && !sortAscending"></i>
              <i class="fa fa-caret-up fa-lg" aria-hidden="true"
                *ngIf="'column7'===currentSortColumn && sortAscending"></i>
            </a>
          </div>
          <div class="block2 flex">
            <a (click)="sortColumns('column8')">
              Due In
              <i class="fa fa-caret-down fa-lg" aria-hidden="true"
                *ngIf="'column8'===currentSortColumn && !sortAscending"></i>
              <i class="fa fa-caret-up fa-lg" aria-hidden="true"
                *ngIf="'column8'===currentSortColumn && sortAscending"></i>
            </a>
          </div>
        </div>
      </div>
    </div>


    <div class="table2Row layout-row" [ngClass]="{'background-white' : !(studentDRIndex === student.key), 
      'background-grey1' : (studentDRIndex === student.key)}"
      *ngFor="let student of DRFilteredItems">
      <div class="flex-95 background-white">
        <!-- data request -->
        <div class="layout-row">
          <div class="flex-90 layout-row section1" (click)="showDRDetailsFn(student.key); updateReadFlag(student)">
            <span class="flex-20 column" [ngClass]="{highlight: currentSortColumn === 'column1'}">
              <p [ngClass]="{unread: isWorklistRead(student.isWorklistRead)}">
                {{student.demographics.lastName1}}<span class="removeUnderline"
                  *ngIf="student.demographics.lastName2 !=''"> {{student.demographics.lastName2}}</span>,
                {{student.demographics.firstName}}
                <span class="removeUnderline" *ngIf="student.demographics.midName != ''">
                  {{student.demographics.midName}}</span>
              </p>
            </span>
            <span class="flex column" [ngClass]="{highlight: currentSortColumn === 'column2'}">
              <p [ngClass]="{unread: isWorklistRead(student.isWorklistRead)}">
                {{student.demographics.submittingStateAbbv}}
              </p>
            </span>
            <span class="flex-15 column" [ngClass]="{highlight: currentSortColumn === 'column3'}">
              <p [ngClass]="{unread: isWorklistRead(student.isWorklistRead)}">
                {{student.demographics.msixId}}
              </p>
            </span>
            <span class="flex-15 column" [ngClass]="{highlight: currentSortColumn === 'column4'}">
              <p [ngClass]="{unread: isWorklistRead(student.isWorklistRead)}">
                {{student.demographics.studentStateID}}
              </p>
            </span>
            <span class="flex-10 column" [ngClass]="{highlight: currentSortColumn === 'column5'}">
              <p [ngClass]="{unread: isWorklistRead(student.isWorklistRead)}">
                {{student.key}}
              </p>
            </span>
            <span class="flex-10 column" [ngClass]="{highlight: currentSortColumn === 'column6'}">
              <p [ngClass]="{unread: isWorklistRead(student.isWorklistRead)}">
                {{student.creator.stateCode}}
              </p>
            </span>
            <span class="flex-15 column" [ngClass]="{highlight: currentSortColumn === 'column7'}">
              <p [ngClass]="{unread: isWorklistRead(student.isWorklistRead)}">
                {{convertDateCreated(student.dateCreated)}}
              </p>
            </span>
            <span class="flex column" [ngClass]="{highlight: currentSortColumn === 'column8'}">
              <p class="flex" *ngIf="student.daysToResolve <= 0"
                [ngClass]="{unread: isWorklistRead(student.isWorklistRead)}">
                Past Due
                <i class="flex fa fa-exclamation-triangle color-red" aria-hidden="true"></i>
              </p>
              <p class="flex" *ngIf="student.daysToResolve == 1"
                [ngClass]="{unread: isWorklistRead(student.isWorklistRead)}">
                {{student.daysToResolve}} day
                <i class="fa fa-exclamation-circle color-mustard" aria-hidden="true"></i>
              </p>
              <p class="flex" *ngIf="student.daysToResolve > 1"
                [ngClass]="{unread: isWorklistRead(student.isWorklistRead)}">
                {{student.daysToResolve}} days
              </p>
            </span>
          </div>
          <div class="flex-10 flex-container">
            <p>
              <a flex (click)="viewDetails(student)" class="top-bottom-margin-8 flexCenter">
                <span class="no-underline">Full Record</span>
              </a>
            </p>
          </div>
        </div>

        <div class="flex-95 section2 worklist-accordion-section2 background-white"
          *ngIf="studentDRIndex === student.key">
          <div class="flex-15 layout-column">
            <div class="col1 flex-10">
              <div class="top-bottom-margin-8">
                <p><b>Sex:</b></p>
                <p>{{student.demographics.gender}}</p>
              </div>
            </div>
          </div>
          <div class="flex-15 layout-column">
            <div class="col1 flex-15">
              <div class="top-bottom-margin-8">
                <p><b>DOB:</b></p>
                <p>{{student.demographics.birthDate}}
                </p>
              </div>
            </div>
            <div class="col1 flex-10">
              <div class="top-bottom-margin-8">
                <p><b>Multi-birth:</b></p>
                <p> {{student.demographics.multipleBirthFlag}}</p>
              </div>
            </div>
          </div>
          <div class="flex-20 layout-column">
            <div class="col1 top-bottom-margin-8">
              <p><b>Parent 1:</b></p>
              <p>{{student.demographics.maleParentFirstName}} {{student.demographics.maleParentLastName}}
              </p>
            </div>
            <div class="col1 top-bottom-margin-8">
              <p><b>Parent 1 Email Address:</b></p>
              <p *ngIf="student.demographics.parent1EmailAddress">{{student.demographics.parent1EmailAddress}}
              </p>
              <p flex
                *ngIf="student.demographics.parent1EmailAddress == '' && !student.demographics.parent1EmailAddress">
                <br>
              </p>
            </div>
            <div class="col1 top-bottom-margin-8">
              <p><b>Parent 1 Phone Number:</b></p>
              <p *ngIf="student.demographics.parent1PhoneNumber">{{student.demographics.parent1PhoneNumber}}
              </p>
              <p flex *ngIf="student.demographics.parent1PhoneNumber == '' && !student.demographics.parent1PhoneNumber">
                <br>
              </p>
            </div>
          </div>
          <div class="flex-20 layout-column">
            <div class="col1 top-bottom-margin-8">
              <p><b>Parent 2:</b></p>
              <p> {{student.demographics.femaleParentFirstName}} {{student.demographics.femaleParentLastName}}
              </p>
            </div>
            
            <div class="col1">
              <div class="top-bottom-margin-8">
                <p><b>Parent 2 Email Address:</b></p>
                <p *ngIf="student.demographics.parent2EmailAddress">{{student.demographics.parent2EmailAddress}}
                </p>
                <p flex *ngIf="student.demographics.parent2EmailAddress == '' && !student.demographics.parent2EmailAddress">
                  <br>
                </p>
              </div>
            </div>
            <div class="col1">
              <div class="top-bottom-margin-8">
                <p><b>Parent 2 Phone Number:</b></p>
                <p *ngIf="student.demographics.parent2PhoneNumber">{{student.demographics.parent2PhoneNumber}}
                </p>
                <p flex *ngIf="student.demographics.parent2PhoneNumber == '' && !student.demographics.parent2PhoneNumber">
                  <br>
                </p>
              </div>
            </div>
          </div>
          <div class="col1 flex-15">
            <a flex *ngIf="!showResolve" (click)="goToReassign(student)" class="top-bottom-margin-8 flexCenter">
              <span>Reassign</span>
            </a>
          </div>
          <div class="col1 flex-15">
            <button flex *ngIf="!showResolve" (click)="resolveModal(student)"
              class="top-bottom-margin-8 md-button">
              Resolve
            </button>
          </div>
        </div>

        <!--correspondence-->
        <div *ngIf="studentDRIndex === student.key">
          <app-correspondence 
            [student]="student" 
            [message]="message" 
            [corMessage]="corMessageDR" 
            (sendCorrespondence)="sendDataRequestCorrespondence($event)"></app-correspondence>
        </div>

        <!-- <div class="background-white flex" *ngIf="studentDRIndex === student.key" layout="row">
          <div class="flex-95">
            <div class="correspondenceSectionHeader worklist-box-border">
              <p><b>Correspondence</b></p>
            </div>
            <div class="correspondenceBody">
              <div class="correspondenceSection" class="flex flex-container">
                <div class="flex-container correspondenceRow flex" layout="row">
                  <p class="flex"><b>{{student.creator.firstName}} {{student.creator.lastName}}</b></p>
                  <p class="flex-25"><b class="pull-right">{{student.creator.dateCreated}}</b></p>
                </div>
                <div class="flex-container correspondenceRow flex" layout="row">
                  <p class="flex">{{student.creator.comments}}</p>
                </div>
              </div>
              <div *ngFor="let message of student.corMessages" class="correspondenceSection flex flex-container">
                <div class="flex-container correspondenceRow flex layout-row">
                  <p class="flex"><b>{{message.msgSubmittingUserFN}} {{message.msgSubmittingUserLN}}</b></p>
                  <p class="flex-25"><b class="pull-right">{{message.msgDate}}</b></p>
                </div>
                <div class="flex-container correspondenceRow flex" layout="row">
                  <p class="flex">{{message.message}}</p>
                </div>
              </div>
              <div class="flex custom-input-container">
                <mat-form-field class="md-block md-input-container-margins background-white comment-box" style="padding-right:1px; padding-bottom:1px">
                  <mat-label class="custom-label">Message</mat-label>
                  <textarea matInput class="custom-input comment-text" [(ngModel)]="corMessageDR.message" maxlength="250"
                    rows="5" md-select-on-focus>
                  </textarea>
                  <div class="md-errors-spacer">
                    <div class="md-char-counter">{{corMessageDR?.message?.trim().length}} / 250</div>
                </div>
                </mat-form-field>
              </div>
              <div class="flex-align-row">
                <div class="flex-85 commentbox-instructions">
                  <p class=" small worklist-commentbox-instructions">
                    Use this form to submit questions regarding the student and their records. This will ensure the
                    correspondence is logged
                    in the MSIX system and will show above.
                  </p>
                </div>
                <button class="md-button" (click)="sendDataRequestCorrespondence(student)" [disabled]="!corMessageDR?.message?.trim().length">
                  Send
                </button>
              </div>
            </div>
          </div>
        </div> -->
      </div>

      <div class="flex-5" (click)="showDRDetailsFn(student.key); updateReadFlag(student)"
        class="expandColumn-green flex-container">
        <i class="fa fa-chevron-down accordion-icon" aria-hidden="true" *ngIf="!(studentDRIndex === student.key)"></i>
        <i class="fa fa-chevron-up accordion-icon" aria-hidden="true" *ngIf="studentDRIndex === student.key"></i>
      </div>

    </div>
  </div>

</div>

<!--END Move Notice Desktop-->

<!--MOBILE AND TABLET START-->

<div class="flex worklist-module top-bottom-margin-5" *ngIf="!responsiveUI.isDesktop && dataRequestItems.length > 0">
  <div layout-padding class="dashboard-module-body">
    <!--mobile & tablet data requests-->
    <div class="top-bottom-margin-8">
      <div class="layout-padding-l layout-padding-r">
        <div class="desktopEnrollments removeBorders">
          <div class="tableHeader">
            <div layout="row" class="tableRow headerRow no-padding">
              <p class="worklist-section-header">Data Requests</p>
            </div>
          </div>
        </div>
        <div class="layout-padding-bottom-15">
          <p>To resolve a data request, access your worklist on desktop.</p>
        </div>

        <div hide-gt-md>
          <div class="results-border top-bottom-padding-8" *ngFor="let student of dataRequestItems">
            <div layout="row" (click)="viewDetails(student)">
              <div flex class="layout-column">
                <a class="uppercase">
                  {{student.demographics.lastName1}}<span class="removeUnderline"
                    *ngIf="student.demographics.lastName2 !=''"> {{student.demographics.lastName2}}</span>,
                  {{student.demographics.firstName}}
                  <span *ngIf="student.demographics.midName != ''"> {{student.demographics.midName}}</span>
                </a>
                <div flex>
                  <p class="small small-grey2andahalf uppercase">ID: {{student.key}} | DOB:
                    {{student.demographics.birthDate}}
                    |&nbsp;{{student.demographics.gender}} | {{student.demographics.submittingStateAbbv}}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</div>

<!--MOBILE AND TABLET END-->